.footerRow1 {
    margin: 0 15px 0 15px;

    .footerColumn1 {
        h5 {
            font-family: 'helveticaNeueLight';
            font-size: 12px;
            color: $greyColor;
        }
    }

}