@media screen and (max-width: 576px) {

    .obrasContainer1 {
        background-color: white;
        width: 100%;
        min-height: 100vh;
        cursor: cell;


        .obrasRow0 {

            .obrasColumn1 {
                ul {
                    display: flex;
                    flex-direction: column;
                    justify-content: start;
                    margin: 0 0 0 0;

                    .categoryTexto {
                        font-size: 8px;
                    }

                    .categoryProgramacion {
                        font-size: 8px;
                    }

                    .categoryInstalacion {
                        font-size: 8px;
                        margin: 0 0 0 0;
                    }

                    .categoryPintura {
                        font-size: 8px;
                        position: relative;
                        left: 0;
                    }

                    .categoryDireccion {
                        font-size: 8px;
                    }
                }
            }
        }


        .obrasRow1 {
            margin: 20px 20px 0 20px;

            .obrasColumn1 {
                margin-top: 0;

                .container1 {
                    width: 100%;
                    margin: 0 auto 10px auto;
                }

                .container1 .content1 {
                    background-color: rgba(0, 0, 0, 0);
                    width: 100%;
                    height: 100%;
                }

                .content1 .txt1 {
                    font-size: 28px;
                    opacity: calc(0);
                }

                .boxTitle1 {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;

                    h3 {
                        font-family: 'retron2000';
                        font-size: 7px;
                        margin-bottom: 0px;
                    }

                    p {
                        font-family: 'helveticaNeueLight';
                        font-size: 6px;
                        color: rgb(55, 55, 55);
                    }
                }
            }

            .obrasColumn2 {
                .container2 {
                    width: 100%;
                    margin: 10px auto 10px auto;
                }

                .container2 .content2 {
                    background-color: rgba(0, 0, 0, 0);
                    width: 100%;
                    height: 100%;
                }

                .content2 .txt2 {
                    font-size: 88px;
                    opacity: calc(0);
                }

                .boxTitle2 {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    margin-bottom: 0;

                    h3 {
                        font-family: 'retron2000';
                        font-size: 7px;
                        margin-bottom: 0px;
                    }

                    p {
                        font-family: 'helveticaNeueLight';
                        font-size: 6px;
                        color: rgb(55, 55, 55);
                    }
                }
            }
        }


        .obrasRow2 {
            .obrasColumn1 {
                .container1 {
                    width: 65%;
                    margin: 0 auto 0 auto;
                    position: relative;
                    top: 0px;
                }

                .container1 .img1 {
                    width: 100%;
                    height: auto;
                    transition: all .5s;
                }

                .container1 .content1 {
                    background-color: rgba(0, 0, 0, 0);
                    width: 100%;
                    height: 100%;
                }

                .content1 .txt1 {
                    font-size: 188px;
                    opacity: calc(0);
                }

                .boxTitle1 {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    width: 84%;
                    margin-left: auto;
                    margin-right: auto;

                    h3 {
                        font-family: 'retron2000';
                        font-size: 7px;
                        margin-bottom: 0px;
                    }

                    p {
                        font-family: 'helveticaNeueLight';
                        font-size: 6px;
                        color: rgb(55, 55, 55);
                    }
                }
            }

            .obrasColumn2 {
                .container2 {
                    width: 84%;
                    margin: 10px auto 10px auto;
                    position: relative;
                    bottom: 0;
                }

                .container2 .content2 {
                    background-color: rgba(0, 0, 0, 0);
                    width: 100%;
                    height: 100%;
                }

                .content2 .txt2 {
                    font-size: 228px;
                    opacity: calc(0);
                }

                .container2:hover .content2 {
                    background-color: rgba(0, 0, 0, 0);
                }
            }

            .boxTitle2 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }
    }

    .obrasRow3 {
        .obrasColumn1 {
            .container1 {
                width: 84%;
                margin: 10px auto 10px auto;
                position: relative;
                top: 0;
            }

            .container1 .content1 {
                background-color: rgba(0, 0, 0, 0);
                width: 100%;
                height: 100%;
            }

            .content1 .txt1 {
                font-size: 178px;
                opacity: calc(0);
            }

            .container1:hover .content1 {
                background-color: rgba(0, 0, 0, 0);
            }

            .boxTitle1 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }

        .obrasColumn2 {
            .container2 {
                width: 75%;
                margin: 0 auto 0 auto;
                position: relative;
                top: 0;
            }

            .container2 .content2 {
                background-color: rgba(0, 0, 0, 0);
                width: 100%;
                height: 100%;
            }

            .content2 .txt2 {
                font-size: 78px;
                opacity: calc(0);
            }

            .container2:hover .content2 {
                background-color: rgba(0, 0, 0, 0);
            }

            .boxTitle2 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                margin-bottom: 0;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }
    }

    .obrasRow4 {
        .obrasColumn1 {
            .container1 {
                width: 84%;
                margin: 10px auto 10px auto;
                position: relative;
                left: 0;
                top: 0;
            }

            .container1 .content1 {
                background-color: rgba(0, 0, 0, .9);
                width: 100%;
                height: 100%;
            }

            .content1 .txt1 {
                font-size: 58px;
                opacity: calc(0);
            }

            .container1:hover .content1 {
                background-color: rgba(0, 0, 0, 0);
            }

            .boxTitle1 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                margin-bottom: 0;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }

        .obrasColumn2 {
            .container2 {
                width: 84%;
                margin: 10px auto 10px auto;
                left: 0px;
                top: 0px;
            }

            .container2 .content2 {
                background-color: rgba(0, 0, 0, 0);
                width: 100%;
                height: 100%;
            }

            .content2 .txt2 {
                font-size: 68px;
                opacity: calc(0);
            }

            .container2:hover .content2 {
                background-color: rgba(0, 0, 0, 0);
            }

            .link1 {
                text-decoration: none;
            }

            .boxTitle2 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                margin-bottom: 0;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }
    }

    .obrasRow5 {
        .obrasColumn1 {
            .container1 {
                width: 84%;
                margin: 10px auto 10px auto;
                position: relative;
                right: 0;
            }

            .container1 .content1 {
                background-color: rgba(0, 0, 0, 0);
                width: 100%;
                height: 100%;
            }

            .content1 .txt1 {
                font-size: 128px;
                opacity: calc(0);
            }

            .container1:hover .content1 {
                background-color: rgba(0, 0, 0, 0);
            }

            .boxTitle1 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                margin-bottom: 0;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }

        .obrasColumn2 {
            .container2 {
                width: 84%;
                margin: 10px auto 10px auto;
                left: 0px;
                top: 0px;
            }

            .container2 .content2 {
                background-color: rgba(0, 0, 0, 0);
                width: 100%;
                height: 100%;
            }

            .content2 .txt2 {
                font-size: 128px;
                opacity: calc(0);
            }

            .container2:hover .content2 {
                background-color: rgba(0, 0, 0, 0);
            }

            .boxTitle2 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                margin-bottom: 0;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }
    }

    .obrasRow6 {
        .obrasColumn1 {
            .container1 {
                width: 85%;
                margin: 10px auto 10px auto;;
                position: relative;
                left: 0;
                top: 0;
            }

            .container1 .content1 {
                background-color: rgba(0, 0, 0, 0);
                width: 100%;
                height: 100%;
            }

            .content1 .txt1 {
                font-size: 218px;
                opacity: calc(0);
            }

            .container1:hover .content1 {
                background-color: rgba(0, 0, 0, 0);
            }

            .boxTitle1 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                margin-bottom: 0;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }

        .obrasColumn2 {
            .container2 {
                width: 85%;
                margin: 10px auto 10px auto;
                left: 0px;
                top: 0px;
                cursor: crosshair;
            }

            .container2 .content2 {
                background-color: rgba(0, 0, 0, 0);
                width: 100%;
                height: 100%;
            }

            .content2 .txt2 {
                font-size: 100px;
                opacity: calc(0);
            }

            .container2:hover .content2 {
                background-color: rgba(0, 0, 0, 0);
            }

            .boxTitle2 {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                margin-bottom: 0;
                width: 84%;
                margin-left: auto;
                margin-right: auto;

                h3 {
                    font-family: 'retron2000';
                    font-size: 7px;
                    margin-bottom: 0px;
                }

                p {
                    font-family: 'helveticaNeueLight';
                    font-size: 6px;
                    color: rgb(55, 55, 55);
                }
            }
        }
    }


    .borde-azul {
        border: 3px solid blue;
        padding: 3px;
    }

    li.active {
        text-decoration: underline;
    }
}