@media screen and (max-width: 576px) {

    .obraArteObjetoContainer1 {
        .obraArteObjetoRow1 {  
            .obraArteObjetoColumn1 {
                div {
    
                    h1 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }
    
        .obraArteObjetoRow2 {
            .obraArteObjetoColumn1 {
                hr {
                    margin: 0 0 8px 0;
                }
    
                div {
                    .obraArteObjetoLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
    
                    .obraArteObjetoTxt {
                        display: flex;
                        flex-direction: column;
    
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }
    
        .obraArteObjetoRow3 {
            .obraArteObjetoColumn1 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
    
                    div {
                        display: flex;
                        width: 90%;
                        height: auto;
    
                        .p1 {
                            text-align: end;
                            font-size: 6px;
                            margin: 10px 0 0 0;
                        }
    
                        p {
                            text-align: end;
                            font-size: 6px;
                        }
                    }
                }
            }
        }
    
        .obraArteObjetoRow4 {
            margin: 0px 35px 0 15px;
    
            .obraArteObjetoColumn1 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                div {
                    align-items: flex-end;
                    justify-content: flex-end;
    
                    .img {
                        width: 90%;
                        height: auto;
                    }
    
                    .p1 {
                        text-align: end;
                        font-size: 6px;

                    }
    
                    p {
                        text-align: end;
                        font-size: 6px;
                    }
                }
            }
    
            .obraArteObjetoColumn2 {
                div {
                    height: auto;
                    justify-content: center;
                    margin: 60px 0 0 0;
                    align-items: center;
    
                    .p1 {
                        text-align: center;
                        font-size: 8px;
                    }
    
                    .p2 {
                        text-align: center;
                        font-size: 8px;
                    }
    
                    .p3 {
                        text-align: center;
                        font-size: 8px;
                    }
    
                }
            }
        }
    
        .obraArteObjetoRow5 {
            margin: 60px 15px 40px 15px;
    
            .obraArteObjetoColumn1 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
    
                    div {
                        width: 85%;
                        height: auto;
    
                        .p1 {
                            font-size: 6px;
                            margin: 10px 0 0 0;
                        }
    
                        p {
                            font-size: 6px;
                        }
                    }
                }
            }
        }
    }
}