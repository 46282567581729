@media screen and (max-width: 576px) {

    .obraVuelveTodoASuLugarContainer1 {
        .obraVuelveTodoASuLugarRow1 {
            .obraVuelveTodoASuLugarColumn1 {
                div {
                    h1 {
                        font-size: 14px;
                        margin: 0 0 6px 0;
                    }
                }
            }
        }
    
        .obraVuelveTodoASuLugarRow2 {
            .obraVuelveTodoASuLugarColumn1 {
                hr {
                    margin: 0 0 10px 0;
                }
    
                div {
                    .obraVuelveTodoASuLugarLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
    
                    .obraVuelveTodoASuLugarTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }
    
        .obraVuelveTodoASuLugarRow3 {
            margin: 20px 15px 0 15px;
    
            .obraVuelveTodoASuLugarColumn1 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                div {
                    iframe {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    
        .obraVuelveTodoASuLugarRow4 {
            margin: 40px 15px 0 15px;
    
            .obraVuelveTodoASuLugarColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                    p {
                        text-align: center;
                        font-size: 8px;
                    }
                }
            }
        }
    
        .obraVuelveTodoASuLugarRow5 {
            margin: 40px 15px 0 15px;
    
            .obraVuelveTodoASuLugarColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    
        .obraVuelveTodoASuLugarRow6 {
            margin: 40px 15px 0 15px;
    
            .obraVuelveTodoASuLugarColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                    margin: 0 0 0 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-right: auto;
                    margin-left: auto;
    
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    
        .obraVuelveTodoASuLugarRow7 {
            margin: 40px 15px 0 15px;
    
            .obraVuelveTodoASuLugarColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    
        .obraVuelveTodoASuLugarRow8 {
            margin: 40px 15px 40px 15px;
    
            .obraVuelveTodoASuLugarColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    
}