@media screen and (max-width: 576px) {

    .obraInfintioContainer1 {
        .obraInfinitoRow1 {
            .obraInfinitoColumn1 {
                div {
                    margin: 20px 15px 0 15px;
    
                    h1 {
                        font-size: 18px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }
    
        .obraInfinitoRow2 {
            .obraInfinitoColumn1 {
                hr {
                    color: black;
                    margin: 0 0 8px 0;
                }
    
                div {
                    .obraInfinitoLink {
    
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
    
                    .obraInfinitoTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }
    
        .obraInfinitoRow3 {
            margin: 0px 15px 0 15px;
    
            .obraInfinitoColumn1 {
                div {
                    .img {
                        width: 80%;
                    }
    
                    div {
                        align-items: center;

                        .p1 {
                            font-size: 8px;
                            margin: 0 0 0 0;
                        }
    
                        p {
                            text-align: center;
                            font-size: 8px;
                            margin: 0 0 0 0;
                        }
                    }
                }
            }
        }
    
        .obraInfinitoRow4 {
            margin: 40px 15px 0 15px;
    
            .obraInfinitoColumn1 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                div {
                    
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
    
            .obraInfinitoColumn2 {
                margin: 20px 15px 0 15px;
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                div {
                    height: auto;
    
                    .p1 {
                        font-size: 8px;
                    }
    
                    .p2 {
                        font-size: 8px;
                    }
    
                    .p3 {
                        font-size: 8px;
                    }
    
                    .p4 {
                        font-size: 8px;
                    }
    
                }
            }
        }
    
        .obraInfinitoRow5 {
            margin: 40px 15px 0 15px;
    
            .obraInfinitoColumn1 {
                margin: 20px 15px 0 15px;
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }
    
        .obraInfinitoRow6 {
            margin: 40px 15px 40px 15px;
    
            .obraInfinitoColumn1 {
                margin: 20px 15px 0 15px;
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                div {
    
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}