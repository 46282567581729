@media screen and (max-width: 576px) {

    .obraPanopticoContainer1 {
        .obraPanopticoRow1 {
            .obraPanopticoColumn1 {
                div {
                    h1 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }
    
        .obraPanopticoRow2 {
            .obraPanopticoColumn1 {
                hr {
                    margin: 0 0 8px 0;
                }
    
                div {
                    .obraPanopticoLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
    
                    .obraPanopticoTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }
    
        .obraPanopticoRow3 {
            margin: 20px 15px 0 15px;
    
            .obraPanopticoColumn1 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
    
                    p {
                        font-size: 6px;
                        margin: 5px 0 0 0;
                    }
                }
            }
    
            .obraPanopticoColumn2 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                div {
                    height: auto;
                    margin: 40px 0 0 0;
                    align-items: center;
    
                    .p1 {
                        text-align: center;
                        font-size: 8px;
                    }
    
                    .p2 {
                        text-align: center;
                        font-size: 8px;
                    }
    
                    .p3 {
                        text-align: center;
                        font-size: 8px;
                    }
    
                    .p4 {
                        text-align: center;
                        font-size: 8px;
                    }
                }
            }
        }
    
        .obraPanopticoRow4 {
            margin: 50px 15px 0 15px;
    
            .obraPanopticoColumn1 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                div {
                    margin: 0 0 0 0;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
    
                    .img {
                        width: 100%;
                        height: auto;
                    }
    
                    div {
                        .p1 {

                            font-size: 6px;
                            margin: 5px 0 0 0;
                        }
    
                        p {
                            font-size: 6px;
                        }
                    }
                }
            }
    
            .obraPanopticoColumn2 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                div {
                    margin: 40px 0 0 0;
    
                    .img {
                        width: 100%;
                        height: auto;
                    }
    
                    div {
                        margin: 5px 0 0 0;
    
                        .p1 {
                            font-size: 6px;
                            margin: 0 0 0 0;
                        }
    
                        p {
                            font-size: 6px;
                        }
                    }
                }
            }
        }
    
        .obraPanopticoRow5 {
            margin: 50px 15px 40px 15px;
    
            .obraPanopticoColumn1 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
    
                    div {
                        width: 100%;
                        height: auto;
    
                        .p1 {
                            font-size: 6px;
                            margin: 5px 0 0 0;
                        }
    
                        p {
                            font-size: 6px;
                        }
                    }
                }
            }
        }
    }
}