@media screen and (max-width: 576px) { 

    .footerRow1 {
        margin: 15px 15px 0 15px;
        
        .footerColumn1 {
            h5 {
                font-size: 8px;
            }
        }
    }
 }
