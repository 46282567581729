@media screen and (max-width: 576px) {

    .obraCuoreContainer1 {
    
        .obraCuoreRow1 {
    
            .obraCuoreColumn1 {
                div {
    
                    h1 {
                        font-size: 18px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }
    
        .obraCuoreRow2 {
    
            .obraCuoreColumn1 {
                hr {
                    margin: 0 0 8px 0;
                }
    
                div {
    
                    .obraCuoreLink {
    
                        h6 {
                            font-size: 6px;
                            line-height: 0;
    
                        }
                    }
    
                    .obraCuoreTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }
    
        .obraCuoreRow3 {
            margin: 80px 15px 0px 15px;
            height: 76.5vh;
    
            .obraCuoreColumn1 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 90%;
                        height: auto;
                    }
                }
            }
        }
    }
    
}