.contactoContainer1 {
    background-color: white;
    width: 100%;
    min-height: 88vh;
    cursor: cell;
}

.contactoRow1 {
    background-color: none;
    min-height: 35vh;
    width: 100%;
    display: flex;
    align-items: flex-end;


    .contactoColumn2 {
        background-color: none;

        a {
            text-decoration: none;

            p {
                font-size: 14px;
                text-align: center;
                font-family: 'helveticaNeueLight';
                color: $greyColor;
                cursor: crosshair;

                &:hover {
                    color: $greenColor;
                }
            }
        }
    }
}

.contactoRow2 {
    background-color: none;
    min-height: 25vh;
    width: 100%;
    display: flex;
    align-items: flex-end;

    .contactoColumn3 {
        background-color: none;

        a {
            text-decoration: none;

            p {
                font-size: 14px;
                text-align: center;
                font-family: 'helveticaNeueLight';
                color: $greyColor;
                cursor: crosshair;

                &:hover {
                    color: $greenColor;
                }
            }
        }
    }
}