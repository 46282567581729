.inicioContainer1 {
    width: 100%;
    height: 100vh;
    background-image: url(../../../public/assets/images/inicio/gifCelineComp3.gif);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: cell;

    .inicioRow1 {
        margin: 0px 0px 0px 0px;
    
        .inicioColumn1 {
            text-align: right;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
    
            h1 {
                font-family: 'retron2000';
                color: white;
                margin: 40px 30px 0px 0px;
                font-size: calc(1rem + 2vw);
                font-style: italic;
            }
    
            img {
                margin-top: 35px;
                width: 240px;
                margin-right: 20px;
            }
    
            hr {
                margin: 0px 30px 0px 30px;
                height: 1px;
                color: white;
            }
        }
    }

    .inicioRow2{
        height: 75vh;

        .inicioColumn1 {
            width: 100vw;
        }
    }
    
    .inicioRow3 {
        margin: 0px 0px 0px 0px;
    
        .inicioColumn1 {
            hr {
                margin: 0px 30px 0px 30px;
                height: 1px;
                color: white;
            }
    
            ul {
                text-align: left;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
    
                .inicioLink {
                    text-decoration: none;
                }
    
                .homeStatement {
                    font-family: 'helveticaNeueLight';
                    font-size: calc(0.4rem + 1vw);
                    color: white;
                    list-style: none;
                    transition: all .1s;
                    cursor: crosshair;
    
                    &:hover {
                        cursor: crosshair;
                        color: $orangeColor;
                    }
                }
    
                .homeObras {
                    font-family: 'helveticaNeueLight';
                    font-size: calc(0.4rem + 1vw);
                    color: white;
                    list-style: none;
                    transition: all .1s;
                    cursor: crosshair;
    
                    &:hover {
                        cursor: crosshair;
                        color: rgb(20, 20, 230);
                    }
                }
    
                .homeArquitectura {
                    font-family: 'helveticaNeueLight';
                    font-size: calc(0.4rem + 1vw);
                    color: white;
                    list-style: none;
                    transition: all .1s;
                    cursor: crosshair;
    
                    &:hover {
                        cursor: crosshair;
                        color: $yellowColor;
                    }
                }
    
                //font original
                .homeContacto {
                    font-family: 'helveticaNeueLight';
                    font-size: calc(0.4rem + 1vw);
                    margin-right: 30px;
                    color: white;
                    margin: 0px 30px 0px 0px;
                    list-style: none;
                    transition: all .1s;
                    cursor: crosshair;
    
                    &:hover {
                        cursor: crosshair;
                        color: $greenColor;
                    }
                }
            }
        }
    }
}

