@media screen and (max-width: 576px) { 

    .contactoContainer1 {
        width: 100%;
        min-height: 87vh;
    }
    
    .contactoRow1 {
    
        .contactoColumn2 {
            a {
                p {
                    font-size: 8px;
    
                    &:hover {
                        color: $greenColor;
                    }
                }
            }
        }
    }
    
    .contactoRow2 {
    
        .contactoColumn3 {
            a {
                p {
                    font-size: 8px;
                    &:hover {
                        color: $greenColor;
                    }
                }
            }
        }
    }
 }