@media screen and (max-width: 576px) {

    .obraFuturoGuionParaLaObraDelFuturoContainer1 {
        .obraFuturoGuionParaLaObraDelFuturoRow1 {
            .obraFuturoGuionParaLaObraDelFuturoColumn1 {
                div {
                    h1 {
                        font-size: 13px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }

        .obraFuturoGuionParaLaObraDelFuturoRow2 {
            .obraFuturoGuionParaLaObraDelFuturoColumn1 {
                hr {
                    margin: 0 0 8px 0;
                }

                div {
                    .obraFuturoGuionParaLaObraDelFuturoLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }

                    .obraFuturoGuionParaLaObraDelFuturoTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }

        .obraFuturoGuionParaLaObraDelFuturoRow3 {
            margin: 20px 15px 0 15px;
            width: 80%;
            margin-left: auto;
            margin-right: auto;

            .obraFuturoGuionParaLaObraDelFuturoColumn1 {
                div {
                    justify-content: center;

                    .p1 {
                        text-align: center;
                        font-size: 8px;
                    }

                    .p2 {
                        text-align: center;
                        font-size: 8px;
                    }

                    .p3 {
                        text-align: center;
                        font-size: 8px;
                    }
                }
            }
        }

        .obraFuturoGuionParaLaObraDelFuturoRow4 {
            margin: 20px 15px 0 15px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .obraFuturoGuionParaLaObraDelFuturoColumn1 {
                div {
                    width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .obraFuturoGuionParaLaObraDelFuturoColumn2 {
                div {
                    width: 62%;
                    margin-left: auto;
                    margin-right: auto;
                    height: auto;

                    audio {
                        width: 100%;
                        height: 100px;
                    }
                }
            }
        }

        .obraFuturoGuionParaLaObraDelFuturoRow5 {
            margin: 20px 15px 0 15px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .obraFuturoGuionParaLaObraDelFuturoColumn1 {
                div {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .img {
                        width: 60%;
                        margin: 25px 0 0 0;
                    }
                }
            }
        }

        .obraFuturoGuionParaLaObraDelFuturoRow6 {
            margin: 20px 15px 0 15px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .obraFuturoGuionParaLaObraDelFuturoColumn1 {
                div {
                    justify-content: center;

                    .img {
                        width: 60%;
                        margin: 10px 0 0 0;
                    }
                }
            }
        }

        .obraFuturoGuionParaLaObraDelFuturoRow7 {
            margin: 45px 15px 0 15px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;

            .obraFuturoGuionParaLaObraDelFuturoColumn1 {
                div {
                    width: 90%;
                    margin-left: auto;
                    margin-right: auto;

                    .img {
                        width: 100%;
                    }
                }
            }
        }
    }

}