/*---------- HELVETICA NEUE ----------*/
@font-face {
    font-family: 'helveticaNeueLight';
    src: url(../../../public/assets/fonts/HelveticaNeue/HelveticaNeueLight.woff) format('truetype');
}

/*---------- RETRON 2000 ----------*/
@font-face {
    font-family: 'retron2000';
    src: url(../../../public/assets/fonts/Retron2000/Retron2000.ttf) format('truetype');
}
