@media screen and (max-width: 576px) {

    .statementContainer1 {
        width: 100%;
        min-height: 87vh;
        display: flex;
        justify-content: center;
        align-items: center;

        .statementRow1 {
            min-height: 0vh;
            width: 100%;
            padding: 0 6px 0 30px;

            .statementColumn1 {

                p {
                    font-size: 8px;
                }
            }
        }

        .statementRow2 {
            width: 100%;

            .statementColumn1 {
                .statementEstudio {
                    width: 100%;

                    .statementEstudioRow1 {
                        padding: 20px 0 0 20px;

                        .statementEstudioCol1 {
                            height: 240px;

                            div {
                                width: 100%;
                                line-height: 12px;

                                .pTit {
                                    line-height: 1px;
                                    font-size: 8px;
                                }

                                p {
                                    font-size: 8px;
                                }
                            }
                        }

                        .statementEstudioCol2 {
                            height: 160px;

                            div {
                                width: 100%;
                                line-height: 12px;

                                .pTit {
                                    line-height: 5px;
                                    font-size: 8px;
                                }

                                p {
                                    font-size: 8px;
                                }
                            }
                        }

                        .statementEstudioCol3 {
                            height: 120px;

                            div {
                                width: 300px;
                                line-height: 12px;

                                .pTit {
                                    line-height: 5px;
                                    font-size: 8px;
                                }

                                p {
                                    font-size: 8px;

                                    a {
                                        color: blue($color: #000000);
                                        font-size: 7px;
                                    }
                                }
                            }
                        }
                    }
                }

                .statementVerMas {
                    p {
                        font-size: 8px;
                    }
                }
            }
        }
    }
}