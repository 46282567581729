.obraIntensidadEfimeraContainer1 {
    background-color: white;

    .obraIntensidadEfimeraRow1 {
        margin: 0 0 0 0;

        .obraIntensidadEfimeraColumn1 {
            div {
                margin: 20px 15px 0 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                h1 {
                    font-family: 'retron2000';
                    font-size: 32px;
                    color: rgb(55, 55, 55);
                }
            }
        }
    }

    .obraIntensidadEfimeraRow2 {
        margin: 0 15px 0 15px;

        .obraIntensidadEfimeraColumn1 {
            hr {
                color: black;
                margin: 0 0 10px 0;
            }

            div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .obraIntensidadEfimeraLink {
                    text-decoration: none;

                    h6 {
                        list-style: none;
                        transition: all .1s;
                        cursor: pointer;
                        font-family: 'helveticaNeueLight';
                        font-size: 16px;
                        color: rgb(55, 55, 55);

                        &:hover {
                            color: $greenColor;
                        }
                    }
                }

                .obraIntensidadEfimeraTxt {
                    display: flex;
                    flex-direction: column;

                    h6 {
                        text-align: end;
                        font-family: 'helveticaNeueLight';
                        font-size: 16px;
                        color: rgb(55, 55, 55);
                    }
                }
            }
        }
    }

    .obraIntensidadEfimeraRow3 {
        margin: 50px 15px 0 15px;

        .obraIntensidadEfimeraColumn1 {
            div {
                margin: 0 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: auto;
                margin-left: auto;

                .img {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .obraIntensidadEfimeraRow4 {
        margin: 150px 15px 0 15px;

        .obraIntensidadEfimeraColumn1 {
            div {
                margin: 0 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;

                .img {
                    width: 100%;
                    height: auto;
                }

                div {
                    // margin: 0 0 0 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-end;
                    width: 60%;
                    height: auto;

                    .p1 {
                        text-align: end;
                        font-family: 'helveticaNeueLight';
                        font-size: 16px;
                        color: rgb(55, 55, 55);
                        margin: 20px 0 0 0;
                    }
                }
            }
        }
    }

    .obraIntensidadEfimeraRow5 {
        margin: 150px 15px 0 15px;

        .obraIntensidadEfimeraColumn1 {
            div {
                margin: 0 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-end;
                margin-right: auto;
                margin-left: auto;

                .img {
                    width: 100%;
                    height: auto;
                }

                div {
                    // margin: 0 0 0 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-end;
                    width: 100%;
                    height: auto;

                    .p1 {
                        text-align: end;
                        font-family: 'helveticaNeueLight';
                        font-size: 16px;
                        color: rgb(55, 55, 55);
                        margin: 20px 0 0 0;
                    }
                }
            }
        }
    }

    .obraIntensidadEfimeraRow6 {
        margin: 150px 15px 150px 15px;

        .obraIntensidadEfimeraColumn1 {
            div {
                margin: 0 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                margin-right: auto;
                margin-left: auto;

                .img {
                    width: 100%;
                    height: auto;
                }

                div {
                    // margin: 0 0 0 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-end;
                    width: 100%;
                    height: auto;

                    .p1 {
                        text-align: end;
                        font-family: 'helveticaNeueLight';
                        font-size: 16px;
                        color: rgb(55, 55, 55);
                        margin: 20px 0 0 0;
                    }
                }
            }
        }
    }
}