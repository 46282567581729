* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

.App {
    text-align: center;
    background-color: white;
    width: 100%;
}