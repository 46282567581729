@media screen and (max-width: 576px) {

    .obraIdiotiqueContainer1 {
        .obraIdiotiqueRow1 {
            .obraIdiotiqueColumn1 {
                div {
                    h1 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }

        .obraIdiotiqueRow2 {
            .obraIdiotiqueColumn1 {
                hr {
                    margin: 0 0 8px 0;
                }

                div {
                    .obraIdiotiqueLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0px;
                        }
                    }

                    .obraIdiotiqueTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0px;
                        }
                    }
                }
            }
        }

        .obraIdiotiqueRow3 {
            margin: 20px 15px 0 15px;

            .obraIdiotiqueColumn1 {
                width: 80%;
            }
        }

        .obraIdiotiqueRow4 {
            margin: 40px 15px 80px 15px;

            .obraIdiotiqueColumn1 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                div {
                    p {
                        font-size: 8px;
                    }

                    .p1 {
                        font-size: 8px;
                        margin: 0 0 0 150px;
                    }
                }
            }

            .obraIdiotiqueColumn2 {
                .boxImg1 {
                    margin: 40px 0 0 0;

                    .img2 {
                        margin: 40px 0 0 0;
                    }

                    .img3 {
                        margin: 40px 0 0 0;
                    }

                    .img4 {
                        width: 80%;
                        margin: 40px 0 0 0;
                    }
                }
            }
        }
    }

}