.statementContainer1 {
    background-color: white;
    width: 100%;
    min-height: 88vh;
    cursor: cell;

    .statementRow1 {
        background-color: none;
        min-height: 45vh;
        width: 100%;
        padding: 0px 100px 0px 100px;
        display: flex;
        align-items: flex-end;

        .statementColumn1 {
            background-color: none;
            
            p {
                font-size: 14px;
                text-align: justify;
                font-family: 'helveticaNeueLight';
                color: rgb(55, 55, 55);
            }
        }
    }

    .statementRow2 {
        background-color: none;
        min-height: 15vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .statementColumn1 {
            background-color: none;

            .statementEstudio {

                .statementEstudioRow1 {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: space-between;
                    padding: 40px 0px 60px 90px;

                    .statementEstudioCol1 {
                        div {
                            width: 450px;
                            height: auto;
                            display: flex;
                            align-items: flex-start;
                            flex-direction: column;
                            align-items: flex-start;

                            .pTit {
                                text-align: left;
                                font-size: 14px;
                                font-family: 'helveticaNeueLight';
                                color: rgb(55, 55, 55);
                            }

                            p {
                                margin: 0 0 0 0;
                                text-align: left;
                                font-size: 14px;
                                font-family: 'helveticaNeueLight';
                                color: rgb(55, 55, 55);
                            }
                        }
                    }

                    .statementEstudioCol2 {
                        div {
                            width: 420px;
                            height: auto;
                            display: flex;
                            justify-content: start;
                            flex-direction: column;
                            align-items: flex-start;

                            .pTit {
                                text-align: left;
                                font-size: 14px;
                                font-family: 'helveticaNeueLight';
                                color: rgb(55, 55, 55);
                            }

                            p {
                                margin: 0 0 0 0;
                                text-align: left;
                                font-size: 14px;
                                font-family: 'helveticaNeueLight';
                                color: rgb(55, 55, 55);
                            }
                        }
                    }

                    .statementEstudioCol3 {
                        div {
                            width: 200px;
                            height: auto;
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: column;
                            align-items: flex-start;

                            .pTit {
                                text-align: left;
                                font-size: 14px;
                                font-family: 'helveticaNeueLight';
                                color: rgb(55, 55, 55);
                            }

                            p {
                                margin: 0 0 0 0;
                                text-align: left;
                                font-size: 14px;
                                font-family: 'helveticaNeueLight';
                                color: rgb(55, 55, 55);

                                a {
                                    color: blue($color: #000000);
                                }
                            }
                        }
                    }
                }
            }

            .statementVerMas {
                display: flex;
                flex-direction: column;
                justify-content: left;
                cursor: crosshair;

                p {
                    text-align: center;
                    font-size: 14px;
                    font-family: 'helveticaNeueLight';
                    color: rgb(55, 55, 55);

                    &:hover {
                        color: $orangeColor;
                    }
                }
            }
        }
    }
}