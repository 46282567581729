.arquitecturaContainer1 {
    background-color: white;
    width: 100%;
    min-height: 88vh;
    cursor: cell;

    .arquitecturaContainerRow0 {
        margin: 0 0 0 0;

        .arquitecturaContainerColumn1 {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
        }
    }

    .arquitecturaContainerRow1 {
        margin: 0 0 0 0;

        .arquitecturaContainerColumn1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .arquitetcuraSliderColumn1 {
                margin-top: 25px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 73%;
                margin-left: auto;
                margin-right: auto;

                .iconLeft {
                    width: 20px;
                    height: auto;
                    cursor: crosshair;
                    color: rgb(55, 55, 55);
                    opacity: (.8);
                    margin: 0 10px 0 0;
                }

                .arquitecturaSliderImg {
                    max-width: 100vw;
                    height: auto;
                    opacity: 0;
                    transition: opacity 0.8s ease-in-out;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .arquitecturaSliderImgActive {
                    opacity: 1;
                }

                .iconRight {
                    width: 20px;
                    height: auto;
                    cursor: crosshair;
                    color: rgb(55, 55, 55);
                    opacity: (.8);
                    margin: 0 0 0 12px;
                }
            }
        }
    }

    .arquitecturaContainerRow2 {
        background-color: none;
        width: 100%;
        padding: 60px 200px 60px 200px;

        .arquitecturaContainerColumn1 {
            background-color: none;

            p {
                font-size: 14px;
                text-align: justify;
                font-family: 'helveticaNeueLight';
                color: rgb(55, 55, 55);
            }
        }
    }
}


.Modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 0 0 0;
    max-width: 500px;
    /* Ancho máximo del modal */
    max-height: 500px;
    /* Altura máxima del modal */
    overflow: auto;

    img {
        max-width: 100%;
        /* Ancho máximo de la imagen */
        max-height: 400px;
        /* Altura máxima de la imagen */
    }

    .btnCerrarModal {
        position: fixed;
        top: 4.8%;
        left: 4.76%;
        transform: translate(-50%, -50%);
        padding: 0 0 0 0;
        overflow: auto;
        background-color: red;
        border: 0 solid grey;
        width: 12px;
        height: 12px;
        color: white;
        font-size: 0;
    }
}