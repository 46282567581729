@media screen and (max-width: 576px) {

    .obraVisualesContainer1 {
        height: auto;
    
        .obraVisualesRow1 {
            .obraVisualesColumn1 {
                div {
                    h1 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }
    
        .obraVisualesRow2 {
            .obraVisualesColumn1 {
                hr {
                    margin: 0 0 8px 0;
                }
    
                div {
                    .obraVisualesLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
    
                    .obraVisualesTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }
    
        .obraVisualesRow3 {
            margin: 20px 15px 0 15px;
    
            .obraVisualesColumn1 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                div {
                    iframe {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    
        .obraVisualesRow4 {
            margin: 40px 15px 33px 15px;
            height: 61vh;
    
            .obraVisualesColumn1 {
                width: 70%;
                margin-left: auto;
                margin-right: auto;

                div {
                    align-items: center;
                    justify-content: center;
    
                    p {
                        text-align: center;
                        font-size: 8px;
                    }

                    .p1{
                        width: 100%;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                        font-size: 6px;
                    }
                }
            }
        }
    }
}
