@media screen and (max-width: 576px) {

    .obraUnoMasUnoTresContainer1 {
        .obraUnoMasUnoTresRow1 {
            .obraUnoMasUnoTresColumn1 {
                div {
                    h1 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }

        .obraUnoMasUnoTresRow2 {
            .obraUnoMasUnoTresColumn1 {
                hr {
                    margin: 0 0 10px 0;
                }

                div {
                    .obraUnoMasUnoTresLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }

                    .obraUnoMasUnoTresTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }

        .obraUnoMasUnoTresRow3 {
            margin: 20px 15px 0 15px;

            .obraUnoMasUnoTresColumn1 {
                div {
                    iframe {
                        width: 100%;
                        height: 250px;
                    }
                }
            }
        }


        .obraUnoMasUnoTresRow4 {
            margin: 10px 15px 0 15px;

            .obraUnoMasUnoTresColumn1 {
                div {
                    width: 80%;
                    margin-right: auto;
                    margin-left: auto;

                    .p1 {
                        text-align: center;
                        font-size: 8px;
                    }

                    .p2 {
                        text-align: center;
                        font-size: 8px;
                    }

                    .p3 {
                        text-align: center;
                        font-size: 8px;
                    }
                }
            }
        }


        .obraUnoMasUnoTresRow5 {
            margin: 40px 15px 5px 15px;

            .obraUnoMasUnoTresColumn1 {
                div {
                    h2 {
                        font-size: 6px;
                        margin: 0 15px 0 0;
                    }
                }
            }
        }

        .obraUnoMasUnoTresRow6 {
            margin: 0 15px 0 15px;
            width: 100%;
            margin-right: auto;
            margin-left: auto;

            .obraUnoMasUnoTresColumn1 {
                div {
                    width: 100%;

                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .obraUnoMasUnoTresRow7 {
            margin: 40px 15px 15px 15px;

            .obraUnoMasUnoTresColumn1 {
                div {
                    h2 {
                        text-align: end;
                        font-size: 6px;
                        margin: 0 15px 0 0;
                    }
                }
            }
        }

        .obraUnoMasUnoTresRow8 {
            margin: 0 15px 45px 15px;

            .obraUnoMasUnoTresColumn1 {
                div {
                    width: 100%;
                    
                    .img {
                        width: 80%;
                        height: auto;
                    }
                }
            }
        }
    }

}