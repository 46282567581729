@media screen and (max-width: 576px) {

    .arquitecturaContainer1 {
        min-height: 75.5vh;

        .arquitecturaContainerRow0 {
            margin: 80px 0 0 0;

            .arquitecturaContainerColumn1 {
                width: 100%;
                height: auto;
            }
        }

        .arquitecturaContainerRow1 {

            .arquitecturaContainerColumn1 {

                .arquitetcuraSliderColumn1 {
                    flex-direction: column;
                    width: 85%;

                    .iconLeft {
                        font-size: 10px;
                        margin: 0 0 0 0;
                        position: relative;
                        right: 120px;
                    }

                    .arquitecturaSliderImg {
                        max-width: 100%;
                    }

                    .iconRight {
                        font-size: 10px;
                        opacity: (.8);
                        margin: 0 0 0 0;
                        position: relative;
                        left: 120px;
                    }
                }
            }
        }

        .arquitecturaContainerRow2 {
            padding: 30px 16px 0 40px;

            .arquitecturaContainerColumn1 {
                p {
                    font-size: 8px;
                }
            }
        }
    }


    .Modal {
        width: 360px;
        height: auto;

        img {
            height: auto;
        }

        .btnCerrarModal {
            width: 10px;
            height: 9px;

            &:hover{
                background-color: rgb(252, 100, 100); 
            }
        }
    }
}
