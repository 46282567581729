@media screen and (max-width: 576px) {

    .inicioContainer1 {
        background-image: url(../../../../public/assets/images/inicio/gifCelineComp3.gif);

        .inicioRow1 {
            .inicioColumn1 {
                img {
                    width: 140px;
                    margin-right: 25px;
                }
            }
        }

        .inicioRow2 {
            height: 30vh;
        }

        .inicioRow3 {
            .inicioColumn1 {
                hr {
                    display: none;
                }

                ul {
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin-right: 30px;

                    .homeStatement {
                        font-size: 16px;
                    }

                    .homeObras {
                        font-size: 16px;
                    }

                    .homeArquitectura {
                        font-size: 16px;
                    }
                    
                    .homeContacto {
                        font-size: 16px;
                        margin: 0px 0px 0px 0px;
                    }
                }
            }
        }
    }
}