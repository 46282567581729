.obrasContainer1 {
    background-color: white;
    width: 100%;
    min-height: 100vh;
    cursor: cell;
}

.obrasRow0 {

    .obrasColumn1 {
        ul {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            bottom: 15px;
            margin: 0 30px 0 0;

            li {
                list-style: none;
                transition: all .1s;
                cursor: crosshair;
            }

            .categoryTexto {
                font-family: 'helveticaNeueLight';
                font-size: calc(0.3rem + 1vw);
                color: rgb(20, 20, 230);
            }

            .categoryProgramacion {
                font-family: 'helveticaNeueLight';
                font-size: calc(0.3rem + 1vw);
                color: rgb(20, 20, 230);
            }

            .categoryInstalacion {
                font-family: 'helveticaNeueLight';
                font-size: calc(0.3rem + 1vw);
                color: rgb(20, 20, 230);
                margin: 0 0 0 0;
                position: relative;
                right: 12px;
            }

            .categoryPintura {
                font-family: 'helveticaNeueLight';
                font-size: calc(0.3rem + 1vw);
                color: rgb(20, 20, 230);
                margin: 0 0 0 0;
            }

            .categoryDireccion {
                font-family: 'helveticaNeueLight';
                font-size: calc(0.3rem + 1vw);
                color: rgb(20, 20, 230);
                margin: 0 0 0 50px;
            }
        }
    }
}


.obrasRow1 {
    margin-top: 30px;

    .obrasColumn1 {
        margin-top: 150px;

        .container1 {
            width: 500px;
            height: auto;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .1s;
            cursor: crosshair;
        }


        .container1 .img1 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container1 .content1 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content1 .txt1 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor;
            cursor: crosshair;
        }

        .container1:hover .content1 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }

    .obrasColumn2 {
        .container2 {
            width: 200px;
            height: auto;
            margin: 50px auto;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .1s;
            cursor: crosshair;
        }

        .container2 .img2 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container2 .content2 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content2 .txt2 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container2:hover .content2 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link2 {
            text-decoration: none;
        }
    }
}

.obrasRow2 {
    .obrasColumn1 {
        .container1 {
            width: 200px;
            height: auto;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .1s;
            position: relative;
            top: 60px;
            cursor: crosshair;
        }

        .container1 .img1 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container1 .content1 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content1 .txt1 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor;
            cursor: crosshair;
        }

        .container1:hover .content1 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }

    .obrasColumn2 {
        .container2 {
            width: 300px;
            height: auto;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .1s;
            position: relative;
            bottom: 50px;
            cursor: crosshair;
        }

        .container2 .img2 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container2 .content2 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content2 .txt2 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container2:hover .content2 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link2 {
            text-decoration: none;
        }
    }
}

.obrasRow3 {
    .obrasColumn1 {
        .container1 {
            width: 600px;
            height: auto;
            margin: 100px 0 0 0px;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .1s;
            cursor: crosshair;
        }

        .container1 .img1 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container1 .content1 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content1 .txt1 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor;
        }

        .container1:hover .content1 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }

    .obrasColumn2 {
        .container2 {
            width: 280px;
            height: auto;
            margin: 170px auto;
            margin-left: 160px;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .1s;
            cursor: crosshair;
        }

        .container2 .img2 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container2 .content2 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content2 .txt2 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container2:hover .content2 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link2 {
            text-decoration: none;
        }
    }
}

.obrasRow4 {
    .obrasColumn1 {
        .container1 {
            width: 430px;
            height: auto;
            margin: 50px 0 0 0;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .2s;
            position: relative;
            right: 20px;
            top: 70px;
            cursor: crosshair;
        }

        .container1 .img1 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container1 .content1 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content1 .txt1 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container1:hover .content1 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }

    .obrasColumn2 {
        .container2 {
            width: 350px;
            height: auto;
            margin: 50px auto;
            margin-right: 55px;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .2s;
            position: relative;
            left: 0px;
            top: 0px;
            cursor: crosshair;
        }

        .container2 .img2 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container2 .content2 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content2 .txt2 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container2:hover .content2 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }
}

.obrasRow5 {
    .obrasColumn1 {
        .container1 {
            width: 650px;
            height: auto;
            margin: 140px auto;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .1s;
            position: relative;
            cursor: crosshair;
        }

        .container1 .img1 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container1 .content1 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content1 .txt1 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container1:hover .content1 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }

    .obrasColumn2 {
        .container2 {
            width: 400px;
            height: auto;
            margin: 180px auto;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .2s;
            position: relative;
            right: 20px;
            top: 20px;
            cursor: crosshair;
        }

        .container2 .img2 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container2 .content2 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content2 .txt2 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container2:hover .content2 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }
}

.obrasRow6 {
    .obrasColumn1 {
        .container1 {
            width: 250px;
            height: auto;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .1s;
            cursor: crosshair;
            position: relative;
            left: 200px;
            top: 140px;
        }

        .container1 .img1 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container1 .content1 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content1 .txt1 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container1:hover .content1 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }

    .obrasColumn2 {
        .container2 {
            width: 600px;
            height: auto;
            margin: 30px auto;
            margin-bottom: 100px;
            font-family: 'retron2000';
            position: relative;
            overflow: hidden;
            transition: all .2s;
            position: relative;
            left: 50px;
            top: 0px;
            cursor: crosshair;
        }

        .container2 .img2 {
            width: 100%;
            height: auto;
            transition: all .5s;
        }

        .container2 .content2 {
            background-color: rgba(0, 0, 0, .7);
            position: absolute;
            top: -150%;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: justify;
            height: 100%;
            color: #fff;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .content2 .txt2 {
            text-align: center;
            font-family: 'retron2000';
            font-size: 12px;
            color: $greenColor
        }

        .container2:hover .content2 {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(0, 0, 0, .9);
        }

        .link1 {
            text-decoration: none;
        }
    }
}


.borde-azul {
    border: 3px solid blue;
    padding: 3px;
}

li.active {
    text-decoration: underline;
}