@media screen and (max-width: 576px) {

    .obraAlephPandemicoContainer1 {
        .obraAlephPandemicoRow1 {
            .obraAlephPandemicoColumn1 {
                div {
                    h1 {
                        font-size: 14px;
                    }
                }
            }
        }
    
        .obraAlephPandemicoRow2 {
            .obraAlephPandemicoColumn1 {
                hr {
                    color: black;
                    margin: 0 0 8px 0;
                }
    
                div {
                    .obraAlephPandemicoLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
    
                    .obraAlephPandemicoTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }
    
        .obraAlephPandemicoRow3 {
            margin: 10px 15px 20px 15px;
    
            .obraAlephPandemicoColumn1 {
                div {
                    .img {
                        width: 110%;
                    }
                }
            }
        }
    }

}