.obraIdiotiqueContainer1 {
    background-color: black;

    .obraIdiotiqueRow1 {
        margin: 0 0 0 0;

        .obraIdiotiqueColumn1 {
            div {
                margin: 20px 15px 0 0;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;

                h1 {
                    font-family: 'retron2000';
                    font-size: 32px;
                    color: white;
                }
            }
        }
    }

    .obraIdiotiqueRow2 {
        margin: 0 15px 0 15px;

        .obraIdiotiqueColumn1 {
            hr {
                color: white;
                margin: 0 0 10px 0;
            }

            div {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .obraIdiotiqueLink {
                    text-decoration: none;

                    h6 {
                        list-style: none;
                        transition: all .1s;
                        cursor: pointer;
                        font-family: 'helveticaNeueLight';
                        font-size: 16px;
                        color: white;

                        &:hover {
                            color: $greenColor;
                        }
                    }
                }

                .obraIdiotiqueTxt {
                    display: flex;
                    flex-direction: column;

                    h6 {
                        text-align: end;
                        font-family: 'helveticaNeueLight';
                        font-size: 16px;
                        color: white;
                    }
                }
            }
        }
    }

    .obraIdiotiqueRow3 {
        margin: 50px 15px 0 15px;

        .obraIdiotiqueColumn1 {
            width: 30%;
            margin-left: auto;
            margin-right: auto;

            div {
                margin: 0 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-right: auto;
                margin-left: auto;
                width: 100%;
                height: auto;

                video {
                    width: 100%;
                    height: auto;
                }
            }
        }
    }

    .obraIdiotiqueRow4 {
        margin: 150px 15px 150px 75px;

        .obraIdiotiqueColumn1 {
            width: 50%;
            margin-left: auto;
            margin-right: auto;

            div {
                margin: 0 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;

                p {
                    text-align: start;
                    font-family: 'helveticaNeueLight';
                    font-size: 16px;
                    color: white;
                    margin: 0 0 0 0;
                }

                .p1{
                    text-align: start;
                    font-family: 'helveticaNeueLight';
                    font-size: 16px;
                    color: white;
                    margin: 0 0 0 200px;
                }
            }
        }

        .obraIdiotiqueColumn2 {
            .boxImg1 {
                height: 100%;
                width: auto;
                margin: 0 0 0 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-evenly;
                margin-right: auto;
                margin-left: auto;

                .img1 {
                    width: 80%;
                    height: auto;
                }

                .img2 {
                    width: 80%;
                    height: auto;
                }

                .img3 {
                    width: 80%;
                    height: auto;
                }

                .img4 {
                    width: 90%;
                    height: auto;
                    margin-right: 50px;
                }
            }
        }
    }
}