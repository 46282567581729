@media screen and (max-width: 576px) {

    .obraIntensidadEfimeraContainer1 {
        .obraIntensidadEfimeraRow1 {
            .obraIntensidadEfimeraColumn1 {
                div {
                    h1 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }

        .obraIntensidadEfimeraRow2 {
            .obraIntensidadEfimeraColumn1 {
                hr {
                    margin: 0 0 8px 0;
                }

                div {
                    .obraIntensidadEfimeraLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }

                    .obraIntensidadEfimeraTxt {
                        display: flex;
                        flex-direction: column;

                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }

        .obraIntensidadEfimeraRow3 {
            margin: 30px 15px 0 15px;

            .obraIntensidadEfimeraColumn1 {
                width: 90%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .obraIntensidadEfimeraRow4 {
            margin: 40px 15px 0 15px;

            .obraIntensidadEfimeraColumn1 {
                width: 70%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }

                    div {
                        .p1 {
                            font-size: 6px;
                            margin: 10px 0 0 0;
                        }
                    }
                }
            }
        }

        .obraIntensidadEfimeraRow5 {
            margin: 40px 15px 0 15px;

            .obraIntensidadEfimeraColumn1 {
                width: 70%;
                margin-left: auto;
                margin-right: auto;

                div {
                    div {
                        .p1 {
                            font-size: 6px;
                            margin: 10px 0 0 0;
                        }
                    }
                }
            }
        }

        .obraIntensidadEfimeraRow6 {
            margin: 40px 15px 40px 15px;

            .obraIntensidadEfimeraColumn1 {
                width: 70%;
                margin-left: auto;
                margin-right: auto;

                div {

                    .img {
                        width: 100%;
                        height: auto;
                    }

                    div {

                        .p1 {
                            font-size: 6px;
                            margin: 10px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}