@media screen and (max-width: 576px) {

    .obraTuVenenoContainer1 {
        .obraTuVenenoRow1 {
            .obraTuVenenoColumn1 {
                div {
                    h1 {
                        font-size: 16px;
                        margin: 0 0 5px 0;
                    }
                }
            }
        }

        .obraTuVenenoRow2 {
            .obraTuVenenoColumn1 {
                div {
                    .obraTuVenenoLink {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }

                    .obraTuVenenoTxt {
                        h6 {
                            font-size: 6px;
                            line-height: 0;
                        }
                    }
                }
            }
        }

        .obraTuVenenoRow3 {
            margin: 20px 15px 0 15px;

            .obraTuVenenoColumn1 {
                width: 100%;
                margin-left: auto;
                margin-right: auto;

                div {
                    iframe {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .obraTuVenenoRow5 {
            margin: 40px 15px 0 15px;

            .obraTuVenenoColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .obraTuVenenoRow6 {
            margin: 40px 15px 0 15px;

            .obraTuVenenoColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .obraTuVenenoRow7 {
            margin: 40px 15px 0 15px;

            .obraTuVenenoColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                  .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }

        .obraTuVenenoRow8 {
            margin: 40px 15px 40px 15px;

            .obraTuVenenoColumn1 {
                width: 80%;
                margin-left: auto;
                margin-right: auto;

                div {
                    .img {
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}